import { useAuth } from "../../../providers/useAuth";
import { NFTToken } from "../../../types/NFTToken";
import Checked from "../../../assets/svg/Checked";

export const RenderNFTs = ({
  tokens = [],
  onSelectNFT = () => {},
  onClick = () => {},
  isFetching = false,
  selectedTokens,
  isMarketplace = true,
}: {
  tokens: NFTToken[] | [];
  isFetching?: boolean;
  selectedTokens?: string[];
  onSelectNFT?: (token: NFTToken) => void;
  onClick?: (token: NFTToken) => void;
  isMarketplace?: boolean;
}) => {
  const { userData } = useAuth();

  // Conditional rendering for loading state, user data, and token presence
  const renderMessage = () => {
    if (isFetching) {
      return (
        <p className="text-4xl text-gray-400 text-center mt-20">Loading...</p>
      );
    }

    if (!userData) {
      return (
        <p className="text-4xl text-gray-400 text-center mt-20">
          Please Login to View Tokens
        </p>
      );
    }

    return (
      <p className="text-4xl text-gray-400 text-center mt-20">No Tokens</p>
    );
  };

  if (tokens.length === 0) {
    return renderMessage();
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 mt-6 ">
      {tokens?.map((token) => {
        const { token_id, metadata } = token;
        const isSelected = selectedTokens?.find((id) => id == token.token_id);

        return (
          <div
            key={token_id}
            className={`border rounded-lg cursor-pointer overflow-hidden relative ${
              isSelected ? "bg-primary" : "hover:bg-primary-300"
            }`}
            onClick={() => onClick(token)}
          >
            {/* Show checkmark only on hover or if selected */}
            {selectedTokens && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onSelectNFT(token);
                }}
                className={`absolute left-2 top-2 rounded-md w-5 h-5 ${
                  isSelected ? "bg-primary" : "bg-white"
                } flex items-center justify-center transition-opacity duration-300
              }`}
              >
                {isSelected && <Checked />}
              </div>
            )}
            {metadata?.media && (
              <img
                src={metadata?.media}
                alt={metadata?.title}
                className="w-full h-[180px] object-cover"
              />
            )}
            <div className="h-20 p-4">
              <h3 className="text-md font-semibold">{metadata?.title}</h3>
              {/* Uncomment the next line if you want to display the description */}
              {/* <p>{metadata.description}</p> */}
            </div>
          </div>
        );
      })}
    </div>
  );
};
