import { useQuery, useMutation, useInfiniteQuery } from "react-query";
import useNearContract from "./useNearContract";
import { NFTToken } from "../../types/NFTToken";
import { toast } from "react-toastify";
import { queryClient } from "../../providers";
import axios from "axios";
import {
  initNear,
  marketContractName,
  nftContractName,
} from "../../config/nearConfig";
import { useAuth } from "../../providers/useAuth";

export default function useMarketplace() {
  const {
    marketContract,
    nearRequest,
    accountId,
    walletRequest,
    contract,
    walletConnection,
  } = useNearContract();
  const { userData } = useAuth();
  let account_id = userData?.near_wallet?.account_id || accountId || "";
  const getMyNFTTokens = async () => {
    // return nearRequest(async () => {
    const response = await contract.current?.nft_tokens_for_owner({
      account_id,
    });
    if (response) return response;
    return [];
    // });
  };

  const getMyListedNFTTokens = async () => {
    // return nearRequest(async () => {
    const listings = await marketContract.current?.get_owner_listings({
      owner_id: account_id,
    });

    if (listings) return listings;
    return [];
    // });
  };

  const getMarketplaceTokens = async () => {
    const listings = (await marketContract.current?.get_all_listings()) || [];

    if (listings.length === 0) return [];

    const tokenIds = listings.map(({ token_id }) => token_id);

    const tokens =
      (await contract.current?.get_token_batch({ token_ids: tokenIds })) || [];

    return tokens;
  };

  const internalListToken = async (token: NFTToken) => {
    const nft = {
      token_id: token.token_id,
      owner_id: token.owner_id,
      approval_id: 1,
      company_id: token.metadata.corporate || "",
      sale_price: "100",
    };
    return nearRequest(async () => {
      const response = await marketContract.current?.nft_list(nft);
      if (!response.success) {
        throw new Error("could not list Nft");
      }
      toast.success("Token Listed");
    });
  };

  const internalUnlistToken = async (token_id: string) => {
    return nearRequest(async () => {
      const response = await marketContract.current?.nft_unlist({
        listings: [token_id],
        contractId: account_id,
      });
      if (!response.success) {
        throw new Error("could not Unlist Nft");
      }
      console.log(response);
      toast.success("Token Removed from Marketplace");
    });
  };

  const internalBuyToken = async ({
    token_ids,
    totalEmission,
  }: {
    token_ids: string[];
    totalEmission: number;
  }) => {
    if (token_ids.length < 1) {
      toast.warn("No Token Selected");
      return { error: true };
    }
    const { data } = await axios.get(
      "https://api.coingecko.com/api/v3/simple/price?ids=near&vs_currencies=usd"
    );
    const USDPerNear = data.near.usd;
    const price = totalEmission * 50;
    const attachedDeposit = BigInt(
      (price / USDPerNear / 1000) * 1000000000000000000000000
    );
    // i divided by 1000 for testing purposes so the price can be a lot lower
    return nearRequest(async () => {
      if (!marketContractName) return;
      const res = await walletConnection.current?.account().functionCall({
        contractId: marketContractName,
        methodName: "offer",
        args: {
          token_ids,
          nft_contract_id: nftContractName,
        },
        gas: BigInt(150000000000000 * token_ids.length),
        attachedDeposit: attachedDeposit,
      });
    });
  };

  const internalBurnTokens = async ({ token_ids }: { token_ids: string[] }) => {
    const owner_id = account_id;
    if (!owner_id) {
      toast.warn("Please Log into wallet");
    } else if (!token_ids || !token_ids.length) {
      toast.warn("Please select Tokens");
    } else {
      return nearRequest(async () => {
        await initNear();
        const res = await walletRequest({
          contractId: nftContractName || "",
          methodName: "nft_burn",
          args: { token_ids, owner_id },
        });
        console.log("Token burnt", res);
      });
    }
  };

  //for Queries
  const useMarketplaceTokens = () =>
    useInfiniteQuery({
      queryFn: getMarketplaceTokens,
      queryKey: ["all-marketplace-tokens"],
      enabled: !!marketContract,
    });
  const useOwnerListedTokens = () =>
    useInfiniteQuery({
      queryFn: getMyListedNFTTokens,
      queryKey: ["owner-marketplace-tokens"],
      enabled: !!marketContract && !!account_id,
    });
  const useMyTokens = () =>
    useQuery({
      queryFn: getMyNFTTokens,
      queryKey: ["my-nft-tokens"],
    });

  //mutation
  const listToken = useMutation({
    mutationFn: internalListToken,
    onSuccess: () => {
      queryClient.invalidateQueries(["owner-marketplace-tokens"]);
    },
  });
  const unlistToken = useMutation({
    mutationFn: internalUnlistToken,
    onSuccess: () => {
      queryClient.invalidateQueries(["owner-marketplace-tokens"]);
    },
  });
  const buyToken = useMutation({
    mutationFn: internalBuyToken,
    onSuccess: (res) => {
      if (res?.error) return;
      toast.success("Token successfully Purchased");
      queryClient.invalidateQueries([
        "owner-marketplace-tokens",
        "all-marketplace-tokens",
      ]);
    },
  });

  const burnTokens = useMutation({
    mutationFn: internalBurnTokens,
    onSuccess: (data) => {
      console.log(data);
    },
  });

  return {
    buyToken,
    listToken,
    unlistToken,
    burnTokens,
    useMarketplaceTokens,
    useOwnerListedTokens,
    useMyTokens,
  };
}
