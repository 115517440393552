import * as buffer from "buffer";
import React, { useEffect } from "react";
import { Contract, WalletConnection } from "near-api-js";

import { MarketContract, NFTContract } from "../../types/Contract";
import {
  initNear,
  marketContractName,
  nftContractName,
} from "../../config/nearConfig";
import { toast } from "react-toastify";

(window as any).Buffer = buffer.Buffer;

export let walletConnection = React.createRef<WalletConnection>();
export let marketContract = React.createRef<MarketContract>();
export let contract = React.createRef<NFTContract>();

export default function useNearContract() {
  const accountId = walletConnection.current?.getAccountId();
  const isSignedIn = walletConnection.current?.isSignedIn();

  const initializeContract = async () => {
    if (!nftContractName || !marketContractName) return;
    if (!marketContract.current || !contract.current) {
      try {
        const { near, wallet } = await initNear();
        //@ts-ignore
        walletConnection.current = wallet;
        if (!wallet) throw new Error(`Near error: Could not connect to Wallet`);
        let accountId = wallet.getAccountId();
        // console.log(`Establishing connection to ${accountId}`);
        const connection = await near.account(accountId);
        // console.log(`Established connection to ${accountId}`);
        //@ts-ignore
        walletConnection.current = wallet;
        // console.log("initializing Marketplace Contract");
        //@ts-ignore
        marketContract.current = new Contract(connection, marketContractName, {
          viewMethods: [
            "get_all_listings",
            "get_company_listings",
            "get_owner_listings",
          ],
          changeMethods: ["nft_list", "nft_unlist", "offer"],
          useLocalViewExecution: true,
        }) as MarketContract;
        // console.log("Marketplace Contract initailized");

        // console.log("initializing Nft Contract");
        //@ts-ignore
        contract.current = new Contract(connection, nftContractName, {
          viewMethods: [
            "nft_tokens",
            "nft_tokens_for_owner",
            "get_token_batch",
          ],
          changeMethods: ["nft_mint", "nft_burn"],
          useLocalViewExecution: false,
        }) as NFTContract;
        // console.log("NFT Contract initailized");
      } catch (error) {
        console.error("Failed to start contract", error);
      }
    }
  };

  //making request to Near Smart Contract
  const nearRequest = async (fn: (params?: any) => Promise<any>) => {
    if (!isSignedIn) {
      toast.warn("Please Login to wallet");
      return { error: "Wallet not connected" };
    }
    try {
      return await fn();
    } catch (error) {
      console.error("Near Error: ", error);
      toast.error("An error occured");
    }
  };

  const walletRequest = async ({
    contractId,
    methodName,
    args,
    gas = 150000000000000,
    attachedDeposit = 0,
  }: {
    contractId: string;
    methodName: string;
    args: Record<string, any>;
    gas?: number;
    attachedDeposit?: number;
  }) => {
    if (!walletConnection) {
      return toast.warn("Wallet not connected");
    }
    return await walletConnection.current?.account().functionCall({
      contractId,
      methodName,
      args,
      gas: BigInt(gas),
      attachedDeposit: BigInt(attachedDeposit),
    });
  };

  const useInit = () =>
    useEffect(() => {
      if (!marketContract.current || !contract.current) {
        initializeContract();
      } else if (marketContract.current && contract.current) {
        console.log("Contract already initialized");
      } else {
        console.log("Contract not initialized");
      }
    }, [accountId]);

  return {
    useInit,
    contract,
    accountId,
    isSignedIn,
    nearRequest,
    walletRequest,
    marketContract,
    walletConnection,
    marketContractName,
  };
}
